<template>
    <div class="o-filter">
        <div class="row no-gutter bg-info pt-5 pb-2">
            <div class="col-12 pl-5 pr-5 o-filter__col">
<!--                @submit.stop.prevent="setFilter"-->
                <form ref="form" class="row no-gutter">
                    <label for="search" class="text-primary font-weight-bold mb-1 o-filter__label">
                        Cerca attività:
                    </label>
                    <b-form-input
                        id="search"
                        placeholder="Cerca"
                        type="text"
                        v-model="currentFilter.search"
                        class="mb-4">
                    </b-form-input>

                    <label for="search" class="text-primary font-weight-bold mb-1 o-filter__label">
                        Filtro invio mail:
                    </label>
                    <div style="width: 100%">
                        <b-form-group label="" v-slot="">
                            <b-form-radio v-model="currentFilter.hasMail" name="filtroHasMail" value="all">Mostra tutti</b-form-radio>
                            <b-form-radio v-model="currentFilter.hasMail" name="filtroHasMail" value="hasMail">Con invio mail</b-form-radio>
                            <b-form-radio v-model="currentFilter.hasMail" name="filtroHasMail" value="noMail">Senza invio mail</b-form-radio>
                        </b-form-group>
                    </div>

                    <date-range :currentFilter="this.currentFilter" @change-range="getDate"></date-range>

                    <b-list-group class="w-100">
                        <p class="text-primary font-weight-bold mb-1  o-filter__label">
                            Tipologia
                        </p>
                        <b-list-group-item
                            class="d-flex justify-content-between align-items-center w-100"
                            v-for="date in listTypeActivity"
                            :key="date.id"
                        >
                            <b-form-checkbox
                                :id="date.activity.code"
                                :name="date.activity.code"
                                v-model="currentFilter.checkedType"
                                :value="date.activity.code"
                            >
                                <span class="w-100 d-flex align-items-center">
                                    <b-badge class="m-badge ml-2 mr-2">
                                        <span>
                                            {{ date.activity.code }}
                                        </span>
                                    </b-badge>
                                    <small>{{ date.activity.name }}</small>
                                </span>
                            </b-form-checkbox>
                        </b-list-group-item>
                    </b-list-group>

<!--                    <b-button block type="submit" variant="primary" class=" mt-3 w-100">-->
<!--                        Applica filtri-->
<!--                    </b-button>-->
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import DateRange from "./moduls/DataRange.vue";
export default {
    props: ["currentFilter"],
    emits: ["change-filter"],
    components: {DateRange},
    name: "AppActivityFilter",

    data() {
        return {
            // activeFilter: {
            //   search: "",
            //   checkedType: [],
            //   dataStart: "01/12/2018",
            //   dataEnd: "01/12/2050",
            // },
            //
            // getDataFilter: {
            //   search: "",
            //   checkedType: [],
            //   dataStart: "01/12/2018",
            //   dataEnd: "01/12/2050",
            // },

            listTypeActivity: [],
        };
    },

    /* computed: {
      listTypeActivity() {
        return this.$store.getters["typeActivity/listActivity"];
      },
    }, */

    created() {
        console.log(this.currentFilter);
        this.getListTypeActivity();
    },

    methods: {
        async getListTypeActivity() {
            try {
                await this.$store.dispatch("typeActivity/getTypeActivity").then(() => {
                    this.listTypeActivity = this.$store.getters[
                        "typeActivity/listActivity"
                        ];
                    this.loading = true;
                    /*   console.log("listTypeActivity"); */
                    return this.listTypeActivity;
                });
            } catch (e) {
                this.loading = false;
                console.log({message: "Some error getContactsPage"});
            }
        },

        // setFilter() {
        //     this.$emit("change-filter", this.currentFilter);
        // },

        getDate(updateRange) {
            console.log(updateRange);
            this.currentFilter.dataStart = new Date(updateRange.start);
            this.currentFilter.dataEnd = new Date(updateRange.end);
        },
    },
};
</script>
