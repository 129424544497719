<template>
  <div>
    <div class="row no-gutters">
      <div class="col-xl-3 col-lg-4 order-2 order-lg-1">
        <section class="o-filter">
          <b-button
            v-b-toggle.filtri
            variant="primary"
            class="d-block d-lg-none mt-1 mb-1 w-100"
            >Filtra risultati</b-button
          >
          <b-collapse visible accordion id="filtri" class="mt-0 o-filter">
            <app-activity-filter
                @change-filter="updateFilters"
                :currentFilter="this.currentActivityFilter"
            ></app-activity-filter>
          </b-collapse>
        </section>
      </div>
      <div class="col-12 col-lg-8 col-xl-9 order-1 order-lg-2">
        <div class="row no-gutters">
          <b-skeleton-wrapper :loading="loading">
            <div class="col-12 pl-2 pr-2">
              <b-skeleton
                width="25%"
                animation="wave"
                class="mt-4 mb-4"
              ></b-skeleton>

              <b-skeleton-table
                :rows="5"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
          </b-skeleton-wrapper>
          <app-activity-table
            v-if="loading == true"
            :getItems="activityList"
            :getRow="activityList.length"
          ></app-activity-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppActivityTable from "../components/AppActivityTable.vue";
import AppActivityFilter from "../components/AppActivityFilter.vue";
import moment from "moment";
export default {
    components: {AppActivityTable, AppActivityFilter},

    name: "Attivita",
    data() {
        return {
            loading: false,
            payload: [],
            currentActivityFilter: {
                search: "",
                checkedType: [],
                dataStart: new Date("01/01/2018"),
                dataEnd: new Date("12/31/2050"),
                hasMail: "all"
            },
            masks: {
                input: "DD/MM/YYYY",
                output: "DD/MM/YYYY",
            },
        };
    },

    computed: {
        hasActivity() {
            return this.$store.getters["activity/hasActivity"];
        },

        activityListRow() {
            return this.$store.getters["activity/nrItem"];
        },

        activityList() {
            console.log('dentro activityList()');

            this.updateFilters(this.currentActivityFilter);

            const activityGet = this.$store.getters["activity/activity"];

            return activityGet.filter((activityGet) => {
                const lineDataStart = moment(String(activityGet.activity.startDt)).format();
                const lineDataEnd = moment(String(activityGet.activity.endDt)).format();
                const filterDataStart = moment(String(this.currentActivityFilter.dataStart)).format();
                const filterDataEnd = moment(String(this.currentActivityFilter.dataEnd)).format();

                // console.log(activityGet.activity.tags, activityGet.activity.tags.toLowerCase().includes(this.currentActivityFilter.search.toLowerCase()));

                //questo primo if screma tutti gli elementi che rientrano nel filtro testuale e nel filtro data (da notare che il filtro testuale lavora su due diversi campi, il nome ed i tags)
                if (
                    (
                        activityGet.activity.name.toLowerCase().includes(this.currentActivityFilter.search.toLowerCase()) ||
                        activityGet.activity.tags.toLowerCase().includes(this.currentActivityFilter.search.toLowerCase())
                    )
                    &&
                    lineDataStart >= filterDataStart && lineDataEnd <= filterDataEnd
                ){
                    //Controllo il filtro sulla tipologia
                    let togle = true;
                    if (this.currentActivityFilter.checkedType.length > 0) {
                        togle = false;
                        this.currentActivityFilter.checkedType.forEach((element) => {
                            if (element === activityGet.activity.activityType_Code) {
                                togle = true;
                            }
                        });
                    }

                    //Aggiungo il filtro sul campo mail (lo eseguo solo se ho superato i filtri precedenti)
                    if(togle){
                        if(this.currentActivityFilter.hasMail == 'hasMail' && !activityGet.activity.sendMail ){
                            togle = false;
                        }else if(this.currentActivityFilter.hasMail == 'noMail' && activityGet.activity.sendMail){
                            togle = false;
                        }
                    }

                    if (togle === true) {
                        return true;
                    }
                }
                return false;
            });
        },
    },

    mounted() {
        this.$store.dispatch("activity/getActivity");
    },

    created() {
        this.getActivity();
        this.syncFilter();
    },

    methods: {
        updateFilters(newFilter) {
            //voglio memorizzare i filtri nello storage
            console.log(newFilter);
            this.currentActivityFilter = newFilter;
            localStorage.setItem("currentActivityFilter", JSON.stringify(this.currentActivityFilter));
        },

        syncFilter() {
            if (localStorage.getItem("currentActivityFilter")) {
                this.currentActivityFilter = JSON.parse(localStorage.getItem("currentActivityFilter"));
            }
        },

        async getActivity() {
            try {
                await this.$store.dispatch("activity/getActivity").then(() => {
                    this.payload = this.$store.getters["activity/activity"];
                    this.loading = true;

                    // return;
                });
            } catch (e) {
                this.loading = false;
                console.log({message: "Some error getActivity"});
            }
        },
    },
};
</script>
