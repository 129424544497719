<template>
    <div class="col-12 pl-2 pr-2 h-100">
        <div class="row no-gutters pt-2 pb-4">
            <div class="col-12">
                <p class="m-list-title">Attività</p>
            </div>
            <div class="col-3">
                Numero risultati:
                <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                    @change="updatePerPage"
                    class="w-auto"
                ></b-form-select>
            </div>

            <div class="col-9">
                <div class="d-flex justify-content-md-end align-items-center h-100">
                    <b-button variant="primary" size="md" v-b-modal.newActivity-id>
                        Nuova attività
                    </b-button>
                </div>
            </div>
        </div>
<!--        sort-compare="datesSorter"-->
        <b-table
            :items="getItems"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            @sort-changed="updateSort"
            :sort-compare="sortActivityTable"
            sort-icon-left
            stacked="md"
            show-empty
            small
            striped
            id="AppActivityTable"
            thead-tr-class="m-table"
        >
            <template #cell(activity.activityType_Code)="row">
                <b-badge class="m-badge">
                    <span>{{ row.item.activity.activityType_Code }}</span>
                </b-badge>
            </template>

            <template #cell(activity.startDt)="row">
                {{ chageFormatDate(row.item.activity.startDt) }}
            </template>

            <template #cell(activity.endDt)="row">
                {{ chageFormatDate(row.item.activity.endDt) }}
            </template>

            <template #cell(activity.dueDt)="row">
                {{ chageFormatDate(row.item.activity.dueDt) }}
            </template>

            <template #cell(Tags)="row">
                {{ row.item.activity.tags }}
            </template>

            <template #cell(Nome)="row">
                {{ row.item.activity.name | truncate(50) }}
            </template>

            <template #cell(activity.sendMail)="row">
                <span v-if="row.item.activity.sendMail">
                    <b-icon
                        font-scale="0.7"
                        icon="check-square"
                        scale="2"
                        variant="success"
                    />
                </span>
                <span v-else>
                    <b-icon
                        font-scale="0.7"
                        icon="x-square"
                        scale="2"
                        variant="danger"
                    />
                </span>
            </template>
            
            <template #cell(actions)="row">
                <b-button
                    size="sm"
                    :to="{
                        name: 'DettagliAttivita',
                        query: { id: row.item.activity.id },
                    }"
                    class="mr-1"
                >
                    Modifica
                </b-button>

                <b-button
                    size="sm"
                    variant="danger"
                    :disabled="row.item.activity.contactsCount > 0"
                    @click="ModalDeleteActivityById(row.item.activity.id)"
                >
                    Elimina
                </b-button>
            </template>
        </b-table>

        <b-pagination
            v-model="currentPage"
            :align="`center`"
            aria-controls="AppActivityTable"
            :per-page="perPage"
            size="sm"
            :total-rows="getRow"
            @input="handlePaginatorClick"
        ></b-pagination>

        <b-modal
            id="newActivity-id"
            title="Nuova attività"
            @show="resetModal"
            @hidden="resetModal"
            ref="modal"
            centered
            hide-footer
            header-class="text-primary"
            body-class="pb-3"
            footer-class="d-flex justify-content-start border-0 pt-2"
        >
<!--            <form ref="form" @submit.stop.prevent="saveLine()">-->
            <b-form @submit="saveLine">
                <b-form-group label="Nome*" label-for="name-input" class="m-input">
                    <b-form-input id="name-input" v-model="newActivity.name" maxlength="100" required>
                    </b-form-input>
                </b-form-group>

                <b-form-group label="Tipologia*" label-for="name-select" class="m-input">
                    <b-form-select
                        id="name-select"
                        v-model="newActivity.activityType_Code"
                        :options="listTypeActivity"
                        value-field="activity.id"
                        text-field="activity.name"
                        required
                    />
                </b-form-group>

                <v-date-picker class="" v-model="newActivity.startDt" :masks="masks">
                    <template v-slot="{ inputValue, inputEvents }">
                        <b-form-group label="Data inizio*" label-for="input-day" class="m-input">
                            <input id="input-day" class="form-control" :value="inputValue" v-on="inputEvents" required/>
                        </b-form-group>
                    </template>
                </v-date-picker>

                <v-date-picker class="" v-model="newActivity.endDt" :masks="masks">
                    <template v-slot="{ inputValue, inputEvents }">
                        <b-form-group label="Data fine*" label-for="input-dayEnd" class="m-input">
                            <input id="input-dayEnd" class="form-control" :value="inputValue" v-on="inputEvents" required />
                        </b-form-group>
                    </template>
                </v-date-picker>

                <v-date-picker class="" v-model="newActivity.dueDt" :masks="masks">
                    <template v-slot="{ inputValue, inputEvents }">
                        <b-form-group
                            label="Data di scadenza per cancellazioni*"
                            label-for="input-dayDue"
                            class="m-input"
                        >
                            <input id="input-dayDue" class="form-control" :value="inputValue" v-on="inputEvents" required />
                        </b-form-group>
                    </template>
                </v-date-picker>

                <b-form-group label="Tags" label-for="name-tags" class="m-input">
                    <b-form-textarea id="name-tags" v-model="newActivity.tags"></b-form-textarea>
                </b-form-group>

                <b-form-checkbox
                    id="new-activity-send-email"
                    v-model="showEmailFields"
                    class="mt-4"
                    :value="true"
                    :unchecked-value="false"
                >
                    Con invio email
                </b-form-checkbox>

                <div v-if="formHasError" class="text-danger">Compila i campi obbligatori</div>

                <b-form-group
                    v-show="showEmailFields"
                    label="Email mittente*"
                    label-for="email-sender-input"
                    class="m-input mt-2"
                >
                    <b-form-input
                        id="email-sender-input"
                        v-model="newActivity.mailFrom"
                        type="email"
                    />
                </b-form-group>

                <b-form-group
                    v-show="showEmailFields"
                    label="Oggetto*"
                    label-for="email-subject-input"
                    class="m-input"
                >
                    <b-form-input
                        id="email-subject-input"
                        v-model="newActivity.mailSubject"
                        type="text"
                    />
                </b-form-group>

                <b-form-group
                    v-show="showEmailFields"
                    label=""
                    label-for="email-message-input"
                    class="m-input"
                >

                    <vue-editor v-model="newActivity.mailBody"></vue-editor>
<!--                    <b-form-textarea-->
<!--                        id="email-message-input"-->
<!--                        v-model="newActivity.mailBody"-->
<!--                    ></b-form-textarea>-->
                </b-form-group>

                <b-button class="mt-2" type="submit" variant="primary">Salva</b-button>
<!--                <b-button type="reset" variant="danger">Reset</b-button>-->
            </b-form>
        </b-modal>

        <b-modal
            id="modal-delete"
            @ok="handleOkDelete"
            size="sm"
            button-size="sm"
            buttonSize="sm"
            okVariant="danger"
            okTitle="YES"
            cancelTitle="NO"
            footerClass="p-2"
            hideHeaderClose centered
        >
            <p><strong>Conferma eliminazione</strong></p>
        </b-modal>
    </div>
</template>

<script>
import moment from "moment";
import {VueEditor} from "vue2-editor";

export default {
    components: {
        VueEditor,
    },
    name: "AppActivityTable",
    props: ["getItems", "getRow", "getFilter"],
    // mount(){
    //     console.log(this.getItems);
    // },
    data() {
        return {
            fields: [
                {
                    key: "activity.activityType_Code",
                    label: "Tipologia",
                    sortable: true,
                    thClass: "",
                    tdClass: "pl-3",
                },
                {
                    key: "activity.startDt",
                    label: "Data inizio",
                    sortable: true,
                },
                {
                    key: "activity.endDt",
                    label: "Data fine",
                    sortable: true,
                },
                {
                    key: "activity.dueDt",
                    label: "Data scadenza",
                    sortable: true
                },
                {
                    key: "activity.name",
                    label: "Nome attività",
                    sortable: true,
                },
                {
                    key: "activity.tags",
                    label: "Tags",
                    sortable: true,
                },
                {
                    key: "activity.contactsCount",
                    label: "N. Contatti",
                    sortable: true,
                },
                {
                    key: 'activity.sendMail',
                    label: 'Con invio mail',
                    sortable: true,
                },
                {
                    key: "actions",
                    label: "",
                    sortable: false,
                    thClass: "",
                    tdClass: "d-flex justify-content-end ",
                },
            ],
            itemDelete: "",
            // Inizializazione tabella
            sortBy: "activity.name",
            sortDesc: false,
            totalRows: 1,
            currentPage: localStorage.getItem("activityCurrentPage") ? parseInt(localStorage.getItem("activityCurrentPage")) : 1,
            perPage: localStorage.getItem("activityCurrentPerPage") ? parseInt(localStorage.getItem("activityCurrentPerPage")) : 20,
            pageOptions: [10, 20, 50, 100, 300, 500],

            newActivity: {
                Day: moment().format(),
                DayEnd: moment().format(),
            },

            showEmailFields: false,
            formHasError: false,

            masks: {
                input: "DD/MM/YYYY",
                output: "DD/MM/YYYY",
            },
        };
    },

    filters: {
        truncate: function (value, size) {
            if (!value) return "";
            value = value.toString();

            if (value.length <= size) {
                return value;
            }
            return value.substr(0, size) + "...";
        },
    },

    computed: {
        listTypeActivity() {
            return this.$store.getters["typeActivity/listActivity"];
        },
        // numberPerPage() {
        //     return this.$store.getters["activity/perPage"];
        // },
    },
    created() {
        this.syncSort()
    },
    methods: {
        // activityFormValidation(inputName) {
        //     if(inputName){
        //         if(inputName.length >= 4) {
        //             return null; //non ritorno true perchè non voglio mostrare il bordo verde
        //         }
        //         return false;
        //     }
        //     return null;
        // },
        // activityFormValidationMessage(inputName) {
        //     if (inputName && inputName.length < 4) {
        //         return 'Inserisci almeno 4 caratteri.'
        //     }
        //     return ''
        // },

        handlePaginatorClick(pageNumber) {
            // your handling logic here
            if(!isNaN(pageNumber)) {
                localStorage.setItem("activityCurrentPage", pageNumber)
            }
        },

        //svuoto la modal
        resetModal() {
            console.log('reset called')
            this.newActivity = [];
        },

        ModalDeleteActivityById($id) {
            this.$bvModal.show("modal-delete");
            this.itemDelete = $id;
        },

        saveLine: function (event) {
            event.preventDefault()
            this.formHasError = false;

            //effetturare validazione campi email (se necessario)
            if(this.showEmailFields){
                if(!this.newActivity.mailFrom || !this.newActivity.mailSubject || !this.newActivity.mailBody){
                    this.formHasError = true;
                    return;
                }
            }

            var data = {
                name: this.newActivity.name,
                code: this.newActivity.name.length,
                activityType_Code: this.newActivity.activityType_Code,
                startDt: moment(String(this.newActivity.startDt)).format(),
                endDt: moment(String(this.newActivity.endDt)).format(),
                tags: this.newActivity.tags,
                dueDt: moment(String(this.newActivity.dueDt)).format(),
                sendMail: this.showEmailFields,
                mailFrom: this.newActivity.mailFrom,
                mailSubject: this.newActivity.mailSubject,
                mailBody: this.newActivity.mailBody,
            };

            // console.log(data);

            this.$store.dispatch("activity/postActivty", data);

            this.$nextTick(() => {
                this.$bvModal.hide("newActivity-id");
            });
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.saveLine();
        },

        /* typeActivityById($id) {
          return this.$store.getters["typeActivity/typeActivityById"]($id).Codice;
        }, */

        async handleOkDelete() {
            /* console.log($id);
            console.log(this.getItems[$id]); */
            /* const $idActivity = this.getItems[$id].idActivity; */
            console.log(this.itemDelete);

            try {
                await this.$store
                    .dispatch("activity/deleteActivty", this.itemDelete)
                    .then(() => {
                        console.log("attività eliminata");
                        // this.$router.go();
                    });
            } catch (e) {
                this.loading = false;
                console.log({ message: "Some error deleteContactsActivity" });
            }

            return;
        },

        /* deleteRow($id) {
          console.log(this.getItems);
          console.log($id);
           this.$store.dispatch("activity/deleteActivty", $id);
        }, */

        chageFormatDate($date) {
            return moment(new Date($date), "YYYY-MM-DD").format("DD/MM/YYYY");
        },

        chageFormatDateIso($date) {
            return moment(new Date($date), "YYYY-MM-DD").format("YYYY-MM-DD");
        },

        handleNewActivitySendEmail() {
            this.showEmailFields = !this.showEmailFields;
        },
        updatePerPage(){
            localStorage.setItem("activityCurrentPerPage", this.perPage);
        },
        syncSort(){
            if(localStorage.getItem("activityCurrentSort")) {
                let sort = JSON.parse(localStorage.getItem("activityCurrentSort"));
                this.sortBy = sort.sortBy;
                this.sortDesc = sort.sortDesc;
            }
        },
        updateSort(e){
            this.sortBy = e.sortBy;
            this.sortDesc = e.sortDesc;

            let sort =  {
                sortBy: this.sortBy,
                sortDesc: this.sortDesc,
            };
            // console.log("updateSort() "+JSON.stringify(sort));
            localStorage.setItem("activityCurrentSort", JSON.stringify(sort));
        },
        sortActivityTable(aRow, bRow, key){
            if(typeof aRow.activity[key] != 'undefined' && typeof bRow.activity[key] != 'undefined'){
                return aRow.activity[key] > bRow.activity[key];
            }

            // const a = aRow[key];
            // const b = bRow[key];
            // if (key === 'activity.startDt' || key === 'activity.endDt') {
            //     return a < b ? -1 : a > b ? 1 : 0
            // }
            
            return null // or false
        }
    },
};
</script>